<template>
    <div>
        <b-row>
            <b-col cols="12">
                
      <b-alert :show="errors.length > 0" v-for="(item, index) in errors" :key="index" variant="danger" dismissible>
        {{item.message}}</b-alert>
            </b-col>
            
        </b-row>
        <b-row v-if="showNoLocationAvailable == true" class="mt-2">
            <b-col cols="12">
                
      <b-alert :show="true" variant="warning" dismissible>No devices available to load data</b-alert>
            </b-col>
            
        </b-row>
        <b-row v-if="isLoading" align-h="center" class="mt-5" align-v="center">
          <b-spinner style="width: 5rem; height: 5rem;" label="Large Spinner"></b-spinner>
        </b-row>
        <b-row v-if="!isLoading">
            <b-col cols="12">
                <b-card class="secondary-background consumed-info">
                    <b-row align-h="between" class="mt-0">
                        <span>Consumed for Period Selected</span>
                        <span>{{totalReading}} kWh</span>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row align-v="center" v-if="!isLoading">
            <b-col cols="12" lg="9">
                <b-card class="skeleton-for-line-graph">
                    <b-skeleton-img v-if="isLoading"></b-skeleton-img>
                    <bar-chart v-if="!isLoading" :data="chartData" :options="chartOptions" :height="80" class="firstgraph"></bar-chart>
                    <bar-chart v-if="!isLoading" :data="chartData" :options="chartOptions" :height="120" class="secondgraph"></bar-chart>
                </b-card>
            </b-col>
            <b-col cols="12" lg="3">
                <b-row class="mt-0">
                    <b-col cols="6" lg="12" class="filter-column ">
                        <b-button :variant="timeLine == 'Week' ? 'primary' : 'secondary'" block @click="searchWeek()">This Week</b-button>
                    </b-col>
                    <b-col cols="6" lg="12" class="filter-column ">
                        <b-button :variant="timeLine == 'Month' ? 'primary' : 'secondary'" block @click="searchMonth()">This Month</b-button>
                    </b-col>
                    <b-col cols="6" lg="12" class="filter-column">
                        <b-button :variant="timeLine == 'Year' ? 'primary' : 'secondary'" block @click="searchYear()">This Year</b-button>
                    </b-col>
                    <b-col cols="6" lg="12" class="filter-column">
                        <b-button :variant="timeLine == 'Custom' ? 'primary' : 'secondary'" block @click="searchCustomDate()">Custom</b-button>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row v-if="timeLine == 'Custom' && !isLoading">
            <b-col cols="12">
                <b-card>
                    <h3>Custom Display Criteria</h3>
                    <b-row>
                        <b-col cols=5>
                            <label for="example-input">Choose a date</label>
                        </b-col>
                        <b-col cols=5>
                            <label for="example-input">Choose a date</label>
                        </b-col>
                    </b-row>
                    <b-row class="mt-0">
                        <b-col cols="12" md="5">
                            <b-input-group class="mb-3">
                                <b-form-input v-model="startDate" type="text" placeholder="YYYY-MM-DD" autocomplete="off">
                                </b-form-input>
                                <b-input-group-append>
                                    <b-form-datepicker v-model="startDate" button-only right locale="en-US"></b-form-datepicker>
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>
                        <b-col cols="12" md="5">
                            <b-input-group class="mb-3">
                                <b-form-input v-model="endDate" type="text" placeholder="YYYY-MM-DD" autocomplete="off">
                                </b-form-input>
                                <b-input-group-append>
                                    <b-form-datepicker v-model="endDate" button-only right locale="en-US"></b-form-datepicker>
                                </b-input-group-append>
                            </b-input-group>
                        </b-col>
                        <b-col cols="12" md="2">
                            <b-button block @click="searchDate()">Search</b-button>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-row v-if="!isLoading">
            <b-col cols="3" class="pr-1">
                <b-button :variant="category == 'ES_Grid' ? 'primary' : 'secondary'" block @click="changeCategory('ES_Grid')">Grid</b-button>
            </b-col>
            <b-col cols="3" class="pl-1 pr-1">
                <b-button :variant="category == 'ES_Solar' ? 'primary' : 'secondary'" block @click="changeCategory('ES_Solar')">Solar</b-button>
            </b-col>
            <b-col cols="3" class="pl-1 pr-1">
                <b-button :variant="category == 'ES_Generator' ? 'primary' : 'secondary'" block @click="changeCategory('ES_Generator')">Generator</b-button>
            </b-col>
            <b-col cols="3" class="pl-1">
                <b-button :variant="category == 'All' ? 'primary' : 'secondary'" block @click="changeCategory('All')">All</b-button>
            </b-col>
        </b-row>
        <b-row v-if="!isLoading">
            <b-col cols="12">
                <b-card class="mb-5">
                    <b-row v-if="isLoading" class="mr-2">
                        <b-col cols="12" class="ml-2">
                            <b-skeleton-table :rows="10" :columns="4" :table-props="{ bordered: false, striped: true }">
                            </b-skeleton-table>
                        </b-col>
                    </b-row>

                    <b-table striped small hover v-if="!isLoading" :items="summaryData" :fields="fields"
                        align-v="center" id="summary-table" class="mb-0" 
                        :sort-by.sync="sortBy" :sort-desc.sync="sortDesc">
                    </b-table>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { bus } from '../main';
import { mapActions } from 'vuex'
    import BarChart from "@/components/BarChart.js";
    export default {
        components: {
            BarChart
        },
        data: () => ({
            category: 'All',
            timeLine: 'Week',
            totalReading: 0,
            startDate: null,
            endDate: null,
            searchEndDate: null,
            sortBy: 'date',
            sortDesc: false,
            isLoading: false,
            isLoadingSummary: false,
            summaryData: [],
            currentPage: 1,
            perPage: 10,
            errors: [],
            fields: [{
                    label: 'Date',
                    key: 'day',
                    sortable: true
                },
                {
                    label: 'Average per Hour (kWh)',
                    key: 'averageKwh',
                    sortable: true
                },
                {
                    label: 'Total (kWh)',
                    key: 'totalKwh',
                    sortable: true
                }
            ],
            chartData: {},
            chartOptions: {
                responsive: false,
                maintainAspectRatio: false,
                legend: {
                    display: false
                },
                tooltips: {
                    enabled: false
                }
            },
      showNoLocationAvailable: false
        }),
        created() {
            //set summary request 
    bus.$on('changedLocation', () => {
    this.doApiCall()

    })
            this.getWeekDates()
            this.doApiCall()
        },
        methods:{
            ...mapActions(['getAllSummarries']),
            concatDate(date){
                let year = date.getFullYear()
                let month = date.getMonth() + 1
                let day = date.getDate()

                let returnDate = year + '-' + month.toString().padStart(2, '0') + '-' + day.toString().padStart(2, '0')
                // let returnDate = year + '-' + month + '-' + day
                return returnDate
            },
            getWeekDates(){
//asjdkaslj
                var curr = new Date(); // get current date  
                var first = curr.getDate() - curr.getDay(); // First day is the  day of the month - the day of the week  
                let startDate = new Date(curr.setDate(first))
                let endDate = new Date(curr.setDate(curr.getDate()+6))

                let lastday = endDate;
                let searchEndDate = new Date(lastday.setDate(lastday.getDate()+1))

                this.startDate = this.concatDate(startDate)
                this.endDate = this.concatDate(endDate)
                this.searchEndDate = this.concatDate(searchEndDate)
            },
            getMonthDates(){
                var date = new Date();
                this.startDate = this.concatDate(new Date(date.getFullYear(), date.getMonth(), 1));
                this.endDate = this.concatDate(new Date(date.getFullYear(), date.getMonth()+1, 0));

                let mon = 0
                if (date.getMonth() != 11) {
                    mon = date.getMonth()+1
                }
                this.searchEndDate = this.concatDate(new Date(date.getFullYear(), mon, 1))
            },
            getYearDates(){
                let curr = new Date;
                let year = curr.getFullYear()

                let nextYear = year + 1

                this.startDate = year + '-01-01'
                this.endDate = year + '-12-31'
                this.searchEndDate = nextYear + '-01-01'
            },
            changeCategory(changeToValue){
                this.category = changeToValue
                this.doApiCall()
            },
            searchCustomDate(){
                this.timeLine = 'Custom'
            },
            searchWeek(){
                this.timeLine = 'Week'
                this.getWeekDates()
                this.doApiCall()
            },
            searchMonth(){
                this.timeLine = 'Month'
                this.getMonthDates()
                this.doApiCall()
            },
            searchYear(){
                this.timeLine = 'Year'
                // let months = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ]
                this.getYearDates()
                this.doApiCall()
                
            },
            searchDate(){
                let indexValue = this.endDate.indexOf("-")
                let year = this.endDate.substr(0,indexValue)
                let stringDayTime = this.endDate.substr(indexValue+1, 5)
                indexValue = stringDayTime.indexOf("-")
                let month = stringDayTime.substr(0, indexValue)
                let day = stringDayTime.substr(indexValue+1)

                let searchEndDate = new Date(year, month-1, day)
                let ab = searchEndDate.setDate(searchEndDate.getDate() +1)
                this.searchEndDate = this.concatDate(new Date(ab))

                this.doApiCall()
            },
            doApiCall(){
            this.errors = []
                let request = {
                    fromDate: this.startDate,
                    toDate: this.searchEndDate,
                    category: this.category
                }
                this.$store.commit('setGetSummaryRequest', request)
                this.isLoading = true
                //call summary request
                this.getAllSummarries()
                .then((response) => {
            if (response.data !== null) {
                    let infoLabels = []
                    let info = []
                    this.summaryData = []
                    let total = 0
                    response.data.forEach(item => {
                        let indexValue = item.dayTime.indexOf("T")
                        let date = item.dayTime.substr(0,indexValue)
                        infoLabels.push(date)
                        info.push(item.kwh.toFixed(2))
                        total += item.kwh
                        this.summaryData.push({day: date, averageKwh: item.avKWpH.toFixed(2), totalKwh: item.kwh.toFixed(2)})
                    });

                    this.totalReading = total.toFixed(2)
                    
                    this.chartData = {
                        hoverBackgroundColor: "red",
                        hoverBorderWidth: 10,
                        labels: infoLabels,
                        datasets: [{
                            label: 'Total kWh',
                            backgroundColor: '#65BC6E',
                            pointBackgroundColor: 'white',
                            borderWidth: 1,
                            pointBorderColor: '#65BC6E',
                            data: info
                        }]
                    }
            }
            else{
              this.showNoLocationAvailable = true
            }
                    this.isLoading = false
                })
                .catch(() => {
                    this.isLoading = false
                    this.errors.push({
                    message: 'Failed to load data. Please refresh and try again.'
                    })
                })
            }
        }
    }
</script>